import React from 'react';
import { UserContext } from '../../UserContext';
import PhotoNotesForm from './PhotoNotesForm';
import styles from './PhotoComments.module.css';
import moment from 'moment';

const PhotoNotes = (props) => {
  const [comments, setComments] = React.useState(() => props.notes);
  const commentsSection = React.useRef(null);
  const { login } = React.useContext(UserContext);

  React.useEffect(() => {
    commentsSection.current.scrollTop = commentsSection.current.scrollHeight;
    //console.log ('comentariox');
    //console.log(props.comments);
    //props.comments = JSON.parse(props.comments); 
  }, [comments]);

  return (
    <>
      <ul
        ref={commentsSection}
        className={`${styles.comments} ${props.single ? styles.single : ''}`}
      >
        
        {comments && comments.map((comment,index) => (
          <li key={index} style={{padding : '10px 10px 10px 0'}}>
            <b>{`${comment.author}`} - { moment(`${comment.created_at}`).format('DD/MM/YYYY HH:mm') }</b>
            <div style={{padding : '10px 10px 10px 0', borderBottom: '#eeeeee 1px solid'} }>{comment.comment}</div>
          </li>
        ))}

      </ul>
      {login && (
        <PhotoNotesForm
          single={props.single}
          id={props.id}
          setComments={setComments}
        />
      )}
    </>
  );
};

export default PhotoNotes;
