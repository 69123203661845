export const API_URL = 'https://www.mybrain.com.br/api';

export function TOKEN_POST(body) {
  //console.log (body);
  return {
    url: API_URL + '/loginjwt',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

export function LIKES_GET(id) {
  return {
    url: `${API_URL}/club/likes/${id}`,
    options: {
      method: 'GET',
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json'
      },
    },
  };
}

export function FAVORITES_GET(id) {
  return {
    url: `${API_URL}/club/favorites/${id}`,
    options: {
      method: 'GET',
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
    },
  };
}

export function FAVORITES_TOOGLE(id) {
  return {
    url: `${API_URL}/club/favorites/${id}`,
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
    },
  };
}

export function LIKES_GET_SITE(id) {
  return {
    url: `${API_URL}/club/likes/site/${id}`,
    options: {
      method: 'GET',
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json'
      },
    },
  };
}

export function TOKEN_VALIDATE_POST(token) {
  return {
    url: API_URL + '/validatejwt',
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json',        
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

export function USER_GET(token) {
  return {
    url: API_URL + '/club/user',
    options: {
      method: 'GET',
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

export function USER_POST(body) {
  return {
    url: API_URL + '/club/newuser',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

export function PHOTO_POST(formData, token) {
  //console.log(formData);
  return {
    url: API_URL + '/club',
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: formData,
    },
  };
}

export function SITE_POST(formData, token) {
  //console.log(formData);
  return {
    url: API_URL + '/club/site',
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: formData,
    },
  };
}

export function MAP_POST(formData, token) {
  //console.log(formData);
  return {
    url: API_URL + '/club/map',
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: formData,
    },
  };
}


export function PAGE_UPDATE(formData, id) {
  return {
    url: `${API_URL}/club/updatepage/${id}`,
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
      body: formData,
    },
  };
}

export function PHOTO_UPDATE(formData, id) {
  console.log(formData);
  return {
    url: `${API_URL}/club/update/${id}`,
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
      body: formData,
    },
  };
}

export function MAP_UPDATE(formData, id) {
  console.log(formData);
  return {
    url: `${API_URL}/club/updatemap/${id}`,
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
      body: formData,
    },
  };
}

export function SITE_UPDATE(formData, id) {
  console.log(formData);
  return {
    url: `${API_URL}/club/update/sitebasic/${id}`,
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
      body: formData,
    },
  };
}

export function PHOTOS_GET({ page, total, user, key = '', category = 0, favorite = 0, area = 0, shared = 0 }) {

  let path = `${API_URL}/club/getall?page=${page}&total=${total}&key=${key}&user=${user}&category=${category}&favorite=${favorite}&area_id=${area}&shared=${shared}`;
  console.log(path);
  return {
    url: path,
    options: {
      method: 'GET',
      cache: 'no-store',
    },
  };
}

export function MAPS_GET({ page, total, user, key = '', category = 0, favorite = 0, area = 5, shared = 0 }) {

  let path = `${API_URL}/club/getall?page=${page}&total=${total}&key=${key}&user=${user}&category=${category}&favorite=${favorite}&area_id=${area}&shared=${shared}`;
  console.log(path);
  return {
    url: path,
    options: {
      method: 'GET',
      cache: 'no-store',
    },
  };
}

export function SITES_GET({ page, total, user, key = '', category = 0, shared = 0 }) {
  console.log('key',key)
  return {
    url: `${API_URL}/club/getallsites?page=${page}&total=${total}&key=${key}&user=${user}&category=${category}&shared=${shared}`,
    options: {
      method: 'GET',
      cache: 'no-store',
    },
  };
}

export function PHOTOS_GET2({ page, total, user, key = '', category = 0, shared = 0 }) {
  console.log('category no GET2',category)
  return {
    url: `${API_URL}/club/getall?page=${page}&total=${total}&key=${key}&user=${user}&category=${category}&shared=${shared}`,
    options: {
      method: 'GET',
      cache: 'no-store',
    },
  };
}

export function PHOTO_SET(id, body) {
  return {
    url: `${API_URL}/club/photo/${id}`,
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    },
  };
}


export function PHOTO_GET(id) {
  return {
    url: `${API_URL}/club/photo/${id}`,
    options: {
      method: 'GET',
      cache: 'no-store',
    },
  };
}

export function MAP_GET(id) {
  return {
    url: `${API_URL}/club/photo/${id}`,
    options: {
      method: 'GET',
      cache: 'no-store',
    },
  };
}


export function SITE_GET(id) {
  return {
    url: `${API_URL}/club/site/${id}`,
    options: {
      method: 'GET',
      cache: 'no-store',
    },
  };
}

export function PHOTO_GET_BY_SLUG(slug) {
  return {
    url: `${API_URL}/club/photo/slug/${slug}`,
    options: {
      method: 'GET',
      cache: 'no-store',
    },
  };
}

export function COMMENT_POST(id, body) {
  //console.log(id);
  //console.log(body);
  return {
    url: `${API_URL}/club/comments/${id}`,
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    },
  };
}

export function NOTE_POST(id, body) {
  //console.log(id);
  //console.log(body);
  return {
    url: `${API_URL}/club/notes/${id}`,
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    },
  };
}

export function COMMENT_POST_SITE(id, body) {
  //console.log(id);
  //console.log(body);
  return {
    url: `${API_URL}/club/comments/site/${id}`,
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    },
  };
}

export function PHOTO_DELETE(id) {
  return {
    url: `${API_URL}/club/${id}`,
    options: {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
    },
  };
}

export function SITE_DELETE(id) {
  return {
    url: `${API_URL}/club/site/${id}`,
    options: {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
    },
  };
}

export function PHOTO_SET2(id, body) {
  return {
    url: `${API_URL}/files/image/update/${id}`,
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    },
  };
}

export function PHOTO_SET_SITE(id, body) {
  return {
    url: `${API_URL}/filessite/image/update/${id}`,
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    },
  };
}

export function PHOTO_DELETE2(id) {
  return {
    url: `${API_URL}/files/image/delete/${id}`,
    options: {
      method: 'DELETE',
      headers: {
      },
    },
  };
}

export function PASSWORD_LOST(body) {
  //console.log(body);
  return {
    url: API_URL + '/forgot-password',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

export function PASSWORD_RESET(body) {
  //console.log(body);
  return {
    url: API_URL + '/reset-password',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

export function STATS_GET() {
  return {
    url: API_URL + '/club/stats',
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
    },
  };
}

export function AREAS_GET() {
  return {
    url: API_URL + '/club/areas',
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
    },
  };
}

export function GRUPOS_GET(id) {
  return {
    url: `${API_URL}/club/grupos/${id}`,
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
    },
  };
}

export function SUBGRUPOS_GET(id) {
  return {
    url: `${API_URL}/club/subgrupos/${id}`,
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
    },
  };
}

export function LESSONS_GET(group,subgroup) {
  return {
    url: `${API_URL}/club/lessons/${group}/${subgroup}`,
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
    },
  };
}

export function FILE_GET(id) {
  return {
    url: `${API_URL}/club/files/${id}`,
    options: {
      method: 'GET',
    },
  };
}

export function NEWS_GET() {
  return {
    url: `${API_URL}/club/getnews`,
    options: {
      method: 'GET',
    },
  };
}

