import React from 'react';
import Courses from './Courses';
import { Routes, Route } from 'react-router-dom';
import Feed from '../Feed/Feed';
import UserPhotoPost from '../User/UserPhotoPost';
import UserMapPost from '../User/UserMapPost';
import UserSitePost from '../User/UserSitePost';
import FeedModalEdit from '../User/FeedModalEdit';
import UserPhotoEdit from '../User/UserPhotoEdit';
import UserPerfilPost from '../User/UserPerfilPost';
import UserData from '../User/UserData';
import UserStats from '../User/UserStats';
import NotFound from '../NotFound';
import Head from '../Helper/Head';
import Panel from '../Panel';
import PanelMaps from '../PanelMaps';
import PanelSites from '../PanelSites';
import Playlist from './Playlist';
import Etapas from './Etapas';
import Lessons from './Lessons';
import { useSelector } from 'react-redux';

const CourseItem = () => {
  //const { data } = React.useContext(UserContext);
  const { data } = useSelector((state) => state.user);

  return (
      <Courses user={data}>
      <Routes>
        <Route path="/" element={<Feed user={data?.id} title="Home"/>} />
        <Route exact path="links" element={<Feed user={data?.id} links='true' title="Links"/>} />
        <Route exact path="videos" element={<Feed user={data?.id} videos='true' title="Videos"/>} />
        <Route path="area" element={<Etapas user={data} title="Etapas"/>} />
        <Route path="lessons" element={<Lessons title="Lições"/>} />
        <Route path="mydata" element={<UserData title="Meus Dados"/>} />
        <Route path="perfil" element={<UserPerfilPost title="Perfil"/>} />
        <Route path="edit" element={<FeedModalEdit title="Editar Ideia"/>} />
        <Route path="post" element={<UserPhotoPost title="Nova Ideia"/>} />
        <Route path="postsite" element={<UserSitePost title="Novo Site"/>} />
        <Route path="postmap" element={<UserMapPost title="Novo Mapa Mental"/>} />
        <Route path="ideas" element={<Panel title="Editar Ideias"/>} />
        <Route path="maps" element={<PanelMaps title="Editar Maps"/>} />
        <Route path="sites" element={<PanelSites title="Editar Sites"/>} />
        <Route exact path="playlist" element={<Playlist title="Playlist"/>} />
        <Route path="stats" element={<UserStats title="Estatísticas"/>} />
      </Routes>
     </Courses> 
  );
};

export default CourseItem;
