import React from 'react';
import styles from './Header.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as Dogs } from '../Assets/logo.svg';
import { loadNewPhotos, loadAll, resetFeedState, setCategory, setFavorito } from '../store/photos';
import { UserContext } from '../UserContext';
import useForm from '../Hooks/useForm';
import Pesquisar from './Forms/pesquisar';
import { appSocket } from '../config/App';
import axios from 'axios';
import { API_URL } from '../Api';

const Header = () => {
  //const { data } = React.useContext(UserContext);
  const { data } = useSelector((state) => state.user);
  const [side, setSide] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    getCategories();
  }, []);

  function getCategories () {
    axios.get(`${API_URL}/getcategories`)
    .then(res => {
        setCategories(res.data);
    })
  }

  function toggleSide() {

    let sidebar = document.querySelector(".sidebarmain");

      if (side) {
        setSide(false);
        sidebar.classList.remove("show-sidebar");
      } else {
        setSide(true);
        sidebar.classList.add("show-sidebar");
      }
  }


  function enviarNotificacao() {

    if (data) {
    appSocket.emit("login", {email: data.email, password: 'senha'}, function(data){
    console.log(data);

      if (data != null) {

        appSocket.emit("entrar", data.name, function(valido){
          console.log ('valido' + valido);
          if(valido){
    
          }else{
            alert("name já utilizado nesta sala");
          }
        });

      }
  
    });
  }
  }

  function sendCategory(item,fav) {
    if (item == 1) item = 0; 
    dispatch(setCategory(item));
    dispatch(setFavorito(fav));
    dispatch(resetFeedState());
    dispatch(loadNewPhotos({ user:0, total: 8, links:true, videos:true }));
  }

  const renderTodos = categories?.map((item, i) => {
    return  (<li key={i}>
      <button onClick={(e) => sendCategory(item.id,0)} className="btn btn-primary m-1 p-1" style={{margin: '0 auto', width: '90%', padding: '10%'}}>{ item.name }</button>
    </li>)
  });

  return (
    <header id="header" className={styles.header}>
      <nav className={`${styles.nav} container`}>
        <Link className={styles.logo} to="/" aria-label="MyBrain - Home">
          <Dogs style={{ maxWidth: '80px', maxHeight: '80px'}}/>
        </Link>
        <Pesquisar/>
        {data ? (
          <Link className={styles.login} to="/count">
            <span className="d-none d-lg-block">{data.username}</span>
          </Link>
        ) : (
          <Link className={styles.login} to="/login">
            Entrar
          </Link>
      )}
          <button className="sidebar-toggle" onClick={() => toggleSide()} style={{border: 'none', background: 'transparent', marginLeft: '10px'}}>
          <i className="fa fa-bars" aria-hidden="true"></i>
          </button>
      </nav>

      <div className="sidebarmain" style={{backgroundColor:'#f2f2f2'}}>
      <div className="sidebar-header">
          <h4>Categorias</h4>
        <button onClick={() => toggleSide()} className="close-btn"><i className="fa fa-times"></i></button>
      </div>
      <ul className="links" style={{paddingLeft: '1rem', listStyle: 'none', display: 'grid', gridTemplateColumns: '1fr 1fr', height: '200px'}}>
        {renderTodos}
      </ul>
      <audio controls id="mp3back" style={{margin: '0 auto'}}>
        <source src={'https://www.mybrain.com.br/storage/logo/58ODV2uIBucazwJKqklpv2g7xllk0isg7n9e0NCX.mp3'} type="audio/mpeg"/>
      </audio>
    </div>

    </header>
  );
};

export default Header;
