import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadNewPhotos } from '../../store/photos';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const Areas = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [area, setArea] = React.useState(searchParams.get("id"));
  const areaplaylist = useSelector((state) => state.playlistdata);
  
  React.useEffect(() => {
    //console.log(area);
  }, []);

  return (
      <ul className="nav nav-treeview">
      {props.areas?.map((item,i) => (
              <li key={i} className="nav-item">
              <Link className="nav-link" to={{pathname: `/courses/area`, search: `?id=${item.id}&desc=${item.title}`}} >
                  {areaplaylist == item.id ? <i className="fas fa-circle nav-icon"></i> : <i className="nav-icon far fa-circle"></i>}
                  <p>{item.title}</p>
              </Link>
            </li>
    ))}
    </ul>
  );

};

export default Areas;
