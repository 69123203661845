import React from 'react';

const IdeasContentItem = ({ e, tipo, photo, setModalPhoto, Change }) => {
  var item, grid, rowHeight, rowGap, rowSpan, allItems, item  = '';
  
  function handleClick() {
    setModalPhoto(photo);
  }

  function resizeGridItemX(e){

    try {
      setTimeout(() => {
        item = document.getElementsByClassName("item")[e];
      grid = document.getElementsByClassName("grid")[0];
      rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
      rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
      rowSpan = Math.ceil((item.querySelector('.content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
        item.style.gridRowEnd = "span "+rowSpan;
  
      }, 1000)
  }
   catch (exception) {
      
   }

  }

  return (
    <div className={`item ${tipo}`} onClick={ photo.priority < 7 ? handleClick : null}>
    <div className="content" style={{position: 'relative'}}>
      <div className="title">
        <h3>{photo.title}</h3>
      </div>      
      {photo.priority >= 7 &&
      <a href={photo.link}>
      <img loading="lazy" className="photothumb scale-up-center" src={photo.src} alt={photo.title}/>
      </a>
      } 
      {photo.priority < 7 &&     
      <p className="text-center p-2" style={{backgroundColor: '#f9f9f9', position: 'relative', bottom: 0, width: '100%', borderRadius: '5px', marginTop: '10px'}}>{photo.diff}</p>
      }
      {photo.body && 
      <div className="desc ck-content" dangerouslySetInnerHTML={{ __html: photo.body }} style={{maxHeight: '500px', overflow: 'hidden'}}>
      </div>
      }      
    </div>
  </div>
  );
};

export default IdeasContentItem;
