import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userLogout } from '../../store/user';
import { AREAS_GET } from '../../Api';
import { ReactComponent as Dogs } from '../../Assets/logo.svg';
import { loadPhotos, searchPhotos, resetFeedState } from '../../store/feed';
import useFetch from '../../Hooks/useFetch';
import Areas from './Areas';

const CoursesAside = (props) => {
  const AreasFetch = useFetch();

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { data } = useSelector((state) => state.user);

  function changeLocation(placeToGo){
    navigate(placeToGo, { replace: true });
    //window.location.reload();
}

React.useEffect(() => {
  async function getData() {
    const { url, options } = AREAS_GET();
    const { json } = await AreasFetch.request(url, options);
    //const json = await response.json();
  }
  getData();
}, [AreasFetch.request]);

function handleSubmit(event) {
  event.preventDefault();
  var inputprocurar = document.getElementById('procurar');
  try {
    if (inputprocurar.value.length) 
      dispatch(searchPhotos({ total: 1000, user:data.id, key:inputprocurar.value }));
      else {
        dispatch(resetFeedState());
        dispatch(loadPhotos({ page: 1,  total: 8, user:data.id }));
      }
    
  } 
  catch(err) {

  }
  }

  return (
<aside className="main-sidebar sidebar-dark-primary elevation-4">
  
    <Link style={{ backgroundColor: '#f9f9f9', color: 'black' }} className="brand-link" to={`/`}>
    <Dogs className="brand-image img-circle"/>
      <span className="brand-text font-weight-light">Painel</span>
    </Link>

    <div className="sidebar">
      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        <div className="image" style={{ marginLeft: '10px'}}>
        <span className="img-circle"><i className="fas fa-user" aria-hidden="true" style={{ color: 'white', marginTop: '10px' }}></i></span>
        </div>
        <div className="info">
          <a href="#" className="d-block">{data?.name}</a>
        </div>
      </div>

      <div className="form-inline">
        <div className="input-group" data-widget="sidebar-search">
          <input id="procurar" onBlur={(e) => handleSubmit(e)} className="form-control form-control-sidebar" type="search" placeholder="Pesquisar" aria-label="Search"/>
          <div className="input-group-append">
            <button id="btnprocurar" onClick={(e) => handleSubmit(e)} className="btn btn-sidebar">
              <i className="fas fa-search fa-fw"></i>
            </button>
          </div>
        </div>
      </div>

      <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li className="nav-item">
          <Link className="nav-link" to="#" >
          <i className="nav-icon fas fa-tachometer-alt"></i>
              <p>
                Cursos
                <i className="right fas fa-angle-left"></i>
              </p>
          </Link>
            <Areas areas={AreasFetch.data}/>
          </li>
          <li className="nav-item">
            <Link to="/courses/playlist"  className="nav-link">
              <i className="nav-icon fas fa-circle"></i>
              <p>
                Minha Playlist
              </p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/courses"  className="nav-link">
              <i className="nav-icon fas fa-circle"></i>
              <p>
                Minhas Ideias
              </p>
            </Link>
          </li>
          <li className="nav-item" style={{ display : 'none'}}>
            <Link to={{pathname: '/courses/links', search: 'links'}} className="nav-link">
              <i className="nav-icon fas fa-circle"></i>
              <p>
                Meus Links
              </p>
            </Link>
          </li>
          <li className="nav-item" style={{ display : 'none'}}>
            <Link to="/courses/videos" className="nav-link">
              <i className="nav-icon fas fa-circle"></i>
              <p>
                Meus Videos
              </p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/courses/mydata" className="nav-link">
              <i className="nav-icon fas fa-calendar-alt"></i>
              <p>
                Meus Dados
              </p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/courses/perfil" className="nav-link">
              <i className="nav-icon fas fa-calendar-alt"></i>
              <p>
                Meu Perfil
                <span className="right badge badge-danger">New</span>
              </p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/courses/post" className="nav-link">
              <i className="nav-icon fas fa-th"></i>
              <p>
                Postar Ideia
              </p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/courses/ideas" className="nav-link">
              <i className="nav-icon fas fa-tree"></i>
              <p>
                Editar Ideias
              </p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/courses/postsite" className="nav-link">
              <i className="nav-icon fas fa-th"></i>
              <p>
                Criar Site
              </p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/courses/sites" className="nav-link">
              <i className="nav-icon fas fa-tree"></i>
              <p>
                Editar Sites
              </p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/courses/postmap" className="nav-link">
              <i className="nav-icon fas fa-th"></i>
              <p>
                Criar Mapa Mental
              </p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/courses/maps" className="nav-link">
              <i className="nav-icon fas fa-tree"></i>
              <p>
                Editar Mapas
              </p>
            </Link>
          </li>
          <li className="nav-header">Outros</li>
          <li className="nav-item">
            <Link to="/courses/stats" className="nav-link">
              <i className="nav-icon fas fa-chart-pie"></i>
              <p>
                Estatísticas
              </p>
            </Link>
          </li>
          <li className="nav-item">
            <div className="nav-link">
              <i className="nav-icon fas fa-chart-pie"></i>
              <p>              
              {`${data?.points} pontos`}
              </p>
            </div>
          </li>
          <li className="nav-item">
            <button onClick={() => dispatch(userLogout())} style={{ backgroundColor : '#212529' }} className="nav-link btn-block">
              <i className="nav-icon fas fa-window-close"></i>
              <p>
                Sair
              </p>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
  );
};

export default CoursesAside;
