import React from 'react';
import styles from './FeedModal.module.css';
import Error from '../Helper/Error';
import Loading from '../Helper/Loading';
import PhotoContent from '../Photo/PhotoContentInterno';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPhoto } from '../../store/photointerno';

const FeedModalInterno = ({ photo, setModal, setModalPhoto }) => {

  const { loading, error, data } = useSelector((state) => state.photointerno);
  const dispatch = useDispatch();
  var mousePosition;
  var offset = [0,0];
  var divnav;
  var isDown = false;

  React.useEffect(() => {

    
    divnav = document.querySelector(".navdiv");
  
    divnav.addEventListener('mousedown', function(e) {
      isDown = true;
      offset = [
          divnav.offsetLeft - e.clientX,
          divnav.offsetTop - e.clientY
      ];
    }, true);
    
    document.addEventListener('mouseup', function() {
        isDown = false;
    }, true);
    
    document.addEventListener('mousemove', function(event) {
        event.preventDefault();
        if (isDown) {
            mousePosition = {
    
                x : event.clientX,
                y : event.clientY
    
            };
            divnav.style.left = (mousePosition.x + offset[0]) + 'px';
            divnav.style.top  = (mousePosition.y + offset[1]) + 'px';
        }
    }, true);
  
   
  }, []); 

  React.useEffect(() => {
    dispatch(fetchPhoto(photo.id));
  }, [dispatch, photo]);

  function handleOutsideClick(event) {
    if (event.target === event.currentTarget) setModal(null);
  }

  return (
    <div className={`${styles.modalInterno} navdiv`} onClick={handleOutsideClick}>
      {error && <Error error={error} />}
      {loading && <Loading />}
      {data && <PhotoContent data={data} onClose={handleOutsideClick} />}
    </div>
  );
};

export default FeedModalInterno;
