import React from 'react';
import { useDispatch } from 'react-redux';
import Content from './PanelMaps/Content';
import Header from './PanelMaps/Header';
import { autoLogin } from '../store/login';

function Panel() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(autoLogin());
    console.log('ver');
  }, [dispatch]);

  return (
    <div className="container">
      <Header />
      <Content />
    </div>
  );
}

export default Panel;
