/* src/api.ts */
export const loadFromServer = () => {
  // using axis(...) or fetch(...) in production code
  return Promise.resolve({
    node: {
      model: { text: "Modelo" },
      view: {
        x: 0,
        y: 0,
        edge: { color: "#9aabaf", name: "mustache_lr", width: 1}
      },
      subs: [
        {
          model: { text: "Canada" },
          view: { x: -100, y: 0 },
          subs: [
            { model: { text: "Toronto" }, view: { x: -90, y: 0 } },
            { model: { text: "Quebec City" }, view: { x: -10, y: -40 } }
          ]
        },
        {
          model: { text: "Spain" },
          view: { x: 100, y: 0 },
          subs: [
            { model: { text: "Madrid" }, view: { x: 90, y: 90 } },
            { model: { text: "Barcelona" }, view: { x: 100, y: 0 } },
            { model: { text: "Valencia" }, view: { x: 90, y: 45 } }
          ]
        }
      ]
    }
  })
}
