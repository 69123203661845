//import IO from 'socket.io-client';

//export const appSocket = IO("https://mybrain.app.br/", {
//  secure: true
//});

import io from "socket.io-client";

//export const appSocket = io("https://mybrain.app.br", {
//    secure: true,
//    path: "/socket.io",
    //transports: ["websocket"],
//    reconnectionAttempts: 30,
//    reconnectionDelay: 2000,
//    reconnection: true,
//});

const socketIOConnOpt = {
  'force new connection': true,
  secure: true,
  reconnection: true,
  reconnectionDelay: 10000,
  reconnectionDelayMax: 60000,
  reconnectionAttempts: 'Infinity',
  timeout: 10000,
  //transports: ['websocket'],
  //resource: '/conversation-api/',
};

export const appSocket = io('https://mybrain.app.br', socketIOConnOpt );

//appSocket.connect();

//appSocket.io.on("error", (err) => {
//    console.error(err);
//});

export const BASE_URL = 'https://www.mybrain.com.br/'
export const API_URL = 'https://www.mybrain.com.br/api'
export const rootUrl = 'https://www.mybrain.com.br/api'
export const MP_PUBLIC_KEY = localStorage.getItem('token');
export const PS_PUBLIC_KEY_OFICIAL = '053AE8DDDC704E6FAB2C1A874AEBD593'
export const PS_PUBLIC_KEY = 'E882B2F7A5384444B0197A7BDA2FF4DE'