import React from 'react';
import styles from './Image.module.css';

const Image = ({ alt, ...props }) => {
  const [skeleton, setSkeleton] = React.useState(true);

  function handleLoad({ target }) {
    setSkeleton(false);
    target.style.opacity = 1;
  }

  function checaextensao () {

    var arq = props.src;
    var ext = arq.split('.').pop();

        if (ext === 'svg') {
          return <iframe onLoad={handleLoad} className={styles.img} alt={alt} {...props} />         
        } else {
          return <img onLoad={handleLoad} className={styles.img} alt={alt} {...props} />
        }

  }

  return (
    <div className={styles.wrapper}>
      {skeleton && <div className={styles.skeleton}></div>}
      {checaextensao()}
    </div>
  );
};

export default Image;
