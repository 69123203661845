import React from 'react';
import styles from './FeedModal.module.css';
import useFetch from '../../Hooks/useFetch';
import Error from '../Helper/Error';
import Loading from '../Helper/Loading';
import { PHOTO_GET } from '../../Api';
import PhotoContent from '../Photo/PhotoContentSite';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../store/ui';
import { fetchSite } from '../../store/site';

const FeedModalSite = ({ photo, setModalPhoto }) => {
  //const { data, error, loading, request } = useFetch();

  const { loading, error, data } = useSelector((state) => state.site);
  const dispatch = useDispatch();
  var mousePosition;
  var offset = [0,0];
  var divnav;
  var isDown = false;

  //const { loading, error, data } = useSelector((state) => state.photo);
  //const dispatch = useDispatch();

  React.useEffect(() => {

    
    divnav = document.querySelector(".navdiv");
  
    divnav.addEventListener('mousedown', function(e) {
      isDown = true;
      offset = [
          divnav.offsetLeft - e.clientX,
          divnav.offsetTop - e.clientY
      ];
    }, true);
    
    document.addEventListener('mouseup', function() {
        isDown = false;
    }, true);
    
    document.addEventListener('mousemove', function(event) {
        event.preventDefault();
        if (isDown) {
            mousePosition = {
    
                x : event.clientX,
                y : event.clientY
    
            };
            divnav.style.left = (mousePosition.x + offset[0]) + 'px';
            divnav.style.top  = (mousePosition.y + offset[1]) + 'px';
        }
    }, true);
  
   
  }, []); 
  

  React.useEffect(() => {
    dispatch(fetchSite(photo.id));
  }, [dispatch, photo]);

  /*
  React.useEffect(() => {
    //const { url, options } = PHOTO_GET(photo.id);
    //request(url, options);
    //console.log ('aqui aqui');

    async function verisso () {
      const { url, options } = PHOTO_GET(photo.id);
      //const json = request(url, options);
      const { response, json } = await request(url, options);
      //console.log ('no modal');
      //console.log (json);
    }
    verisso();


  }, [photo, request]);
  */

  function handleOutsideClick(event) { 
    if (event.target === event.currentTarget) setModalPhoto(null);
   // if (event.target === event.currentTarget) dispatch(closeModal());
  }

  return (
    <div className={`${styles.modal} navdiv`} onClick={handleOutsideClick}>
      {error && <Error error={error} />}
      {loading && <Loading />}
      {data && <PhotoContent data={data} onClose={handleOutsideClick} />}
    </div>
  );
};

export default FeedModalSite;
